import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpecialZonePickerValues } from 'store/ui/uiutil';
import { getDevEnvironment } from 'store/uiSettings/selectors';
import { Zones } from 'store/zone/reducer';
import { selectAvailableZones } from 'store/zone/selector';

export enum ValueType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
}

export enum SegmentType {
  'DriveUp' = 1,
  'Booking' = 2,
}
// Kan ligga i rules context
export type Rule = {
  id?: number;
  name?: string;
  facilityId?: number;
  valueType?: ValueType;
  configuratinType?: number;
  instruction?: string;
  level?: number;
};

export interface ZoneRule {
  id: number;
  dynZoneId: number;
  configurationId: number; // Rule id
  value: string;
  groupId?: number;
  segment: string;
}

export interface GroupRule {
  id: number;
  groupId: number;
  configurationId: number; // Rule id
  value: string;
  segment: number;
}

export type Group = {
  name: string;
  id: number;
  zoneIds: string[];
  rules: GroupRule[];
  facilityId: number;
};

export type ConfigContextType = {
  groups: Group[];
  zones: Zones;
  rules: Rule[];
  setGroups: React.Dispatch<React.SetStateAction<Group[]>>;
  setZones: (zones: Zones) => void;
  setRules: (rules: Rule[]) => void;
  setFacilityId: (facilityId: string) => void;
  editGroup: (updatedGroup: Group) => void;
  createGroup: (newGroup: Group) => void;
  deleteGroup: (groupId: number) => void;
  isLoading: boolean;
};

// Editable gorup i context - on i create / annars edit - skicka med i modal
export const ConfigContext = React.createContext<ConfigContextType>(null!);

export const ConfigContextProvider: React.FC = ({ children }) => {
  const availableZones = useSelector(selectAvailableZones);
  const [groups, setGroups] = useState<Group[]>([]);
  const [zones, setZones] = useState<Zones>([]);
  const [rules, setRules] = useState<Rule[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityId, setFacilityId] = useState<string>('');
  var isDevelopment = useSelector(getDevEnvironment);

  // Fetch groups
  const fetchGroups = async (facilityId: string) => {
    setIsLoading(true);
    if (!isDevelopment) {
      setGroups(getSpecialZonePickerValues(facilityId));
      return;
    }
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/get/groups`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch groups: ${response.statusText}`);
      }
      const data: Group[] = await response.json();
      setGroups(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };

  // Fetch rules
  const fetchRules = async () => {
    try {
      const response = await fetch(`/api/Configuration/get/rules`);

      if (!response.ok) {
        throw new Error(`Failed to fetch rules: ${response.statusText}`);
      }

      const data: Rule[] = await response.json();
      setRules(data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      alert('Failed to fetch group data. Please try again later.');
    }
  };

  // Create new group
  const createGroup = async (newGroup: Group) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/create/group`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newGroup),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create group: ${response.statusText}`);
      }
      const createdGroup: Group = await response.json();
      setGroups((prevGroups) => [...prevGroups, createdGroup]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating group:', error);
      alert('Failed to create the group. Please try again later.');
    }
  };

  // Edit group
  const editGroup = async (updatedGroup: Group) => {
    setIsLoading(true);
    console.log(updatedGroup, 'Test EditGroup');
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/edit/group`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedGroup),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update group: ${response.statusText}`);
      }
      const responseGroup: Group = await response.json();
      console.log(responseGroup, 'Response EditGroup');
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === responseGroup.id ? responseGroup : group
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  // Delete group
  const deleteGroup = async (groupId: number) => {
    setIsLoading(true);
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/Configuration/${facilityId}/delete/group/${groupId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const updatedGroups: Group[] = await response.json();
      setGroups(updatedGroups);
      setIsLoading(false);
    } catch (error) {
      console.error('Fail to delete!', error);
    }
  };

  useEffect(() => {
    if (!!facilityId) {
      const filtered = availableZones.filter(
        (zone) => zone.sitePath === facilityId
      );
      setZones(filtered);
      fetchGroups(facilityId);
      fetchRules();
    }
  }, [facilityId, availableZones]);
  return (
    <ConfigContext.Provider
      value={{
        groups,
        zones,
        rules,
        setGroups,
        setZones,
        setRules,
        setFacilityId,
        editGroup,
        createGroup,
        deleteGroup,
        isLoading,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);

