import Button from 'components/input/Button';
import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import useModalStack from './useModalStack';
import CloseButton from './CloseButton';

export const titleClass = 'modalTitle';
export const childWrapClass = 'modalChildWrap';
export const buttonsWrapClass = 'modalButtonsWrap';

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 501;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.2s;

  background-color: ${({ theme }) => theme.palette.common.darkOverlay};
`;

const TheModal = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 200px;
  min-width: 940px;
  max-width: 95vw;
  max-height: calc(95vh - 50px);
  background-color: ${({ theme }) => theme.palette.text.bg};
  color: ${({ theme }) => theme.palette.text.fg};
  border-radius: 6px;
  animation: ${fadeIn} 0.6s;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 16px;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 2.5rem;
  padding-top: 2.5rem;
`;

const Title = styled.h1`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;

  font-family: 'Source Serif Pro', serif;
  font-size: 32px;
  font-weight: 700;
`;

const ChildWrap = styled.div<{ noPadding?: boolean }>`
  flex: 1;
  overflow-y: auto;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 2.5rem;
    `}
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem;
`;

const MyButton = styled(Button)`
  margin-right: 10px;
`;

export interface ModalButton {
  label: React.ReactChild;
  /** if left undefined it defaults to closing the modal */
  onClick?(): void;
  dontPopAfterClick?: boolean;
  disabled?: boolean;
}

interface Props {
  buttons?: ModalButton[];
  title?: string;

  /** prevent padding on child container */
  noPadding?: boolean;
  notClosable?: boolean;

  className?: string;
}

const Modal: FC<Props> = ({
  children,
  buttons,
  title,
  noPadding,
  notClosable,
  className,
}) => {
  const modalStack = useModalStack();

  const handleClose = () => {
    if (!notClosable) {
      modalStack.pop();
    }
  };

  return (
    <Overlay onClick={handleClose}>
      <TheModal onClick={(eve) => eve.stopPropagation()} className={className}>
        <TitleWrap>
          {title && <Title className={titleClass}>{title}</Title>}
          {!notClosable && <CloseButton onClick={handleClose} />}
        </TitleWrap>

        <ChildWrap noPadding={noPadding} className={childWrapClass}>
          {children}
        </ChildWrap>

        {buttons && (
          <ButtonsWrap className={buttonsWrapClass}>
            {buttons.map((button, index) => (
              <MyButton
                key={index}
                onClick={() => {
                  button.onClick && button.onClick();
                  if (!button.dontPopAfterClick) {
                    modalStack.pop();
                  }
                }}
                disabled={button.disabled}
              >
                {button.label}
              </MyButton>
            ))}
          </ButtonsWrap>
        )}
      </TheModal>
    </Overlay>
  );
};

export default Modal;
