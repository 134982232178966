import { MiddlewareAPI, Dispatch } from 'redux';

import { calcCacheUpdate, clearCacheUpdate } from '../calcCache/action';
import { CCSTORE } from '../calcCache/constants';
import { CacheEntry, Price_Resp } from '../calcCache/definitions';
import { ApplicationState } from '../definitions';
import { EVENTSTOREACTIONS } from '../event/constants';
//import { selectHistoryFacilityMatch } from './router/selector';
import { uiResetToFetch, uiSetDataTS, usSetFailedToFetch } from '../ui/action';
import { UISTORE } from '../ui/constants';
import { PriceSchedule, Pricing, Zone } from '../zone/reducer';
import {
  selectAvailableFacilityZones,
  selectCurrentZones,
} from '../zone/selector';
import { buildReq, execUpdate } from './zoneUpdateRequestHelpers';
import { ZONESTORE, ZONEUPDATES } from 'store/zone/constants';

// :(()=>(api:MiddlewareAPI<Dispatch, any>,action:any)=>void)
export const makeZoneUpdatePoll = (
  dateGetter: (state: ApplicationState) => { from: string; to: string },
  cacheGetter: (state: ApplicationState) => CacheEntry | undefined,
  uiTimeStampToAction: (ts: number) => any,
  cacheToAction: (data: CacheEntry) => any
) => {
  let prevReq = '';
  let inFlight = null as string | null;
  let controllerAbort: () => void = () => {};
  let setController: boolean = false;
  //let pendingReq = null as ;

  const zoneUpdatePoll = (api: MiddlewareAPI<Dispatch, any>, action: any) => {
    // Post-state we ignore the following messages.
    if (
      action.type === CCSTORE.CLEAR_CACHE ||
      action.type === UISTORE.FAILED_TO_FETCH ||
      action.type === UISTORE.RESET_FETCH ||
      action.type === UISTORE.CHANGE_ACTIVE_FACILITY
    ) {
      return;
    }
    // if the user updates/adds/removes events then we will force an update since that user change has changed the preconditions for what we cache!
    const force =
      !!Object.keys(EVENTSTOREACTIONS).find(
        (f) =>
          EVENTSTOREACTIONS[f as keyof typeof EVENTSTOREACTIONS] === action.type
      ) || ZONEUPDATES.includes(action.type);

    // console.log(ZONEUPDATES, action.type, force);

    // get the state produced by the previous call to next()
    const newState = api.getState() as ApplicationState;
    //const facilityAndZoneMatch = selectHistoryFacilityMatch(newState);

    //console.log('Updater:', newState, facilityAndZoneMatch);

    // pick the active zone as well as all zones for the current facility.
    const curZones = selectCurrentZones(newState);
    const allZones = selectAvailableFacilityZones(newState);

    //console.log(allZones, newState.uiState.activeFacility);
    // now make a list of the zones for the current request (ie only the picked one or all for the current facility!)
    const reqZones = curZones.length === 0 ? allZones : curZones;

    // Make a list of the eventually wanted pricings in the form of a list with zoneId / segment pairs
    const wantedPricings = reqZones.flatMap((rz) =>
      (newState.uiState.segments === 'all'
        ? ['driveup', 'booking']
        : [newState.uiState.segments]
      ).map((segment) => ({
        zoneId: rz.id,
        segment,
      }))
    );

    // Pick out the preview-schedule for each zone.
    const scheduleSets = reqZones
      .map((curZone) =>
        !curZone
          ? null
          : !curZone.schedules
          ? null
          : curZone.schedules.length == 0
          ? null
          : {
              zone: curZone,
              schedule: curZone.schedules.reduce(
                (acc, cur) =>
                  (acc.previewActivationTimeStamp ?? 0) >
                  (cur.previewActivationTimeStamp ?? 0)
                    ? acc
                    : cur,
                curZone.schedules[0]
              ),
            }
      )
      .filter((ss) => !!ss) as { zone: Zone; schedule: PriceSchedule }[];

    // const pricingSets = reqZones
    //   // pick out the active pricing (if one exists) for each zone
    //   .map(
    //     (curZone) =>
    //       curZone &&
    //       curZone.pricings && {
    //         zone: curZone,
    //         priceSet: curZone.pricings.find(
    //           (testPricing) => testPricing.id === curZone.selectedPricing
    //         )!,
    //       }
    //   )
    //   // remove zones that lack pricings (they shouldn't but let's avoid crashes!)
    //   .filter((tp) => !!tp.priceSet);

    //console.log('Updater:', curZone, allZones);

    const dates = dateGetter(newState);

    // build a request-object to send to the backend
    const req = buildReq(
      newState.uiState.segments,
      dates.from,
      dates.to,
      scheduleSets
    );
    // console.log('REQ: ', req, newState.uiState, action);
    if (req.schedules.length === 0 || req.segments.length == 0) {
      api.dispatch(clearCacheUpdate());
      api.dispatch(usSetFailedToFetch());
      return; // no relevant pricings.
    }
    // make the completed JSON object
    //console.log('ZU:', req);
    const reqStr = JSON.stringify(req);
    if (
      !force &&
      reqStr === prevReq &&
      action.type !== UISTORE.CHANGE_ACTIVE_FACILITY_COMPLETE
    )
      return;

    // all conditions are right for us to update the request!
    const { startDate, endDate } = newState.uiState;

    //TODO FIX THIS
    if (
      !force &&
      cacheGetter(newState) &&
      cacheGetter(newState)!.startDate === startDate &&
      cacheGetter(newState)!.endDate === endDate
    ) {
      if (
        cacheGetter(newState)!.data &&
        // zoneIdSet check done with wantedPricings to see if we already have the wanted data.
        wantedPricings.reduce(
          (acc, cur) =>
            acc &&
            !!cacheGetter(newState)?.data.zones.find(
              (f) =>
                f.zoneId === cur.zoneId.toString() && f.kind === cur.segment
            ),
          true
        )
        // curZone &&
        // !!newState.calcCache.cached.data.zones.find(
        //   (f) => f.zoneId === curZone.id.toString()
        // )
      ) {
        console.log('GOT DATA ALREADY!', curZones);
        return;
      }
    }

    if (force || inFlight !== reqStr) {
      // timestamp used for UI to know what request response to use.
      const ts = new Date().valueOf();

      const priceRespCB = (data: Price_Resp) => {
        api.dispatch(
          cacheToAction({
            cacheTS: ts,
            data,
            zones: curZones!,
            // We calculate a minimal/unique zone id set from the response.
            zoneIdSet: Array.from(
              new Set(data.zones.map((z) => z.zoneId)).values()
            ).sort(),
            startDate,
            endDate,
          })
        );
      };
      const err = () => {
        api.dispatch(usSetFailedToFetch());
      };
      const reset = () => {
        api.dispatch(uiResetToFetch());
      };

      const reqData = { req: reqStr, cb: priceRespCB, err, reset };

      controllerAbort();
      execUpdate(
        reqData,
        (cur) => (inFlight = cur),
        (abort) => {
          setController = true;
          controllerAbort = abort;
        }
      );
      api.dispatch(uiTimeStampToAction(ts));
    }
    prevReq = reqStr;
  };

  return zoneUpdatePoll;
};
