import { Checkbox, Input, StatelessTable } from '@bitio/react-components';
import {
  ColumnDef,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { RoundButton } from 'components/input/Button';
import Select from 'components/input/Select';
import {
  GroupRule,
  Rule,
  useConfigContext,
} from 'pages/Configuration/contexts/configurationContext';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaSave, FaTrashAlt } from 'react-icons/fa';
import { styled } from 'styled-components';
import { segmentOptions } from './AddRule';

// This Extend TableMeta to include updateData which is a built-in function to update data in the table
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

interface RulesTableProps {
  groupRules: GroupRule[];
  updateGroupRule: (updatedRule: GroupRule) => void;
  deleteGroupRule: (ruleId: number) => void;
}

const CellWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

// Helper function to render the value input based on the type - TODO: MOVE TO seperate file
const renderValueInput = (
  value: string | number | boolean,
  handleChange: (value: unknown) => void
) => {
  switch (typeof value) {
    case 'boolean':
      return (
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(e.currentTarget.checked)}
        />
      );
    case 'number':
      return (
        <Input
          type="number"
          value={value.toString()}
          onChange={(e) => handleChange(Number(e.target.value))}
        />
      );
    case 'string':
    default:
      return (
        <Input
          type="text"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      );
  }
};
// Component
const RulesTable: React.FC<RulesTableProps> = ({
  groupRules,
  updateGroupRule,
  deleteGroupRule,
}) => {
  const { rules } = useConfigContext();
  const [data, setData] = useState<GroupRule[]>(groupRules || []);
  const [currentlyEditing, setCurrentlyEditing] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    deleteGroupRule(id);
  };

  const startEditing = (id: number) => setCurrentlyEditing(id);

  const updateData = (rowIndex: number, columnId: string, value: unknown) => {
    setData((oldData) =>
      oldData.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      )
    );
  };

  const saveChanges = (updatedGroup: GroupRule) => {
    if (!updatedGroup) return;
    updateGroupRule(updatedGroup);
    setCurrentlyEditing(null);
  };

  useEffect(() => {
    if (!groupRules) return;
    setData(groupRules);
  }, [groupRules]);
  const columns: ColumnDef<GroupRule>[] = [
    {
      header: 'Rule Name',
      accessorKey: 'name',
      minSize: 220,
      maxSize: 250,
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const isEditableRow = currentlyEditing === data[index]?.id;
        return isEditableRow ? (
          <Select
            value={getValue() as string}
            onChange={(e) =>
              table.options.meta?.updateData(index, id, e.target.value)
            }
          >
            {rules.map((rule) => (
              <option key={`edit-rule-${rule.id}`} value={rule.name}>
                {rule.name}
              </option>
            ))}
          </Select>
        ) : (
          <option>
            {(groupRules &&
              rules.find((rule) => rule.id === data[index]?.configurationId)
                ?.name) ||
              'No rule selected'}
          </option>
        );
      },
    },
    {
      header: 'Segment',
      accessorKey: 'segment',
      minSize: 200,
      maxSize: 250,
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const isEditableRow = currentlyEditing === data[index]?.id;
        return isEditableRow ? (
          <Select
            value={(getValue() as string) || ''}
            onChange={(e) =>
              table.options.meta?.updateData(index, id, e.target.value)
            }
          >
            <option value="">Select segment</option>
            {segmentOptions.map((seg, idx) => (
              <option key={`segment-${seg}-${idx}`} value={seg}>
                {seg}
              </option>
            ))}
          </Select>
        ) : (
          <option>{(getValue() as string) || 'No segment selected'}</option> // TODO: Change to display value
        );
      },
    },
    {
      header: 'Value',
      accessorKey: 'value',
      minSize: 150,
      maxSize: 250,
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const isEditableRow = currentlyEditing === data[index]?.id;
        return isEditableRow ? (
          renderValueInput(
            getValue() as string | number | boolean,
            (newValue) => table.options.meta?.updateData(index, id, newValue)
          )
        ) : (
          <span>{getValue()?.toString() || 'Has no value'}</span>
        );
      },
    },
    {
      header: 'Actions',
      minSize: 80,
      maxSize: 200,
      cell: ({
        row: {
          index,
          original: { id },
        },
      }) =>
        currentlyEditing === id ? (
          <CellWrapper>
            <RoundButton onClick={() => saveChanges(data[index])}>
              <FaSave />
            </RoundButton>
            <RoundButton onClick={() => handleDeleteRow(id)}>
              <FaTrashAlt />
            </RoundButton>
          </CellWrapper>
        ) : (
          <CellWrapper>
            <RoundButton onClick={() => startEditing(id)}>
              <FaEdit />
            </RoundButton>
          </CellWrapper>
        ),
    },
  ];
  const table = useReactTable({
    data,
    columns,
    meta: { updateData },
    getCoreRowModel: getCoreRowModel(),
  });

  return <StatelessTable table={table} asList />;
};

export default RulesTable;

