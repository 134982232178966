import React from 'react';
import Select from 'components/input/Select';
import {
  Rule,
  SegmentType,
  useConfigContext,
} from 'pages/Configuration/contexts/configurationContext';
import { styled } from 'styled-components';
import Button, { ButtonColors } from 'components/input/Button';
import Input from 'components/input/Input';
import { ValueType } from 'pages/Configuration/contexts/rulesContext';
import Label from 'components/input/Label';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
`;
export const segmentOptions: SegmentType[] = [
  SegmentType.DriveUp,
  SegmentType.Booking,
];

export const segmentLabels: { [key in SegmentType]: string } = {
  [SegmentType.DriveUp]: 'Drive Up',
  [SegmentType.Booking]: 'Booking',
};

interface AddRuleProps {
  addGroupRule: (ruleId: number, segment: number, value: string) => void;
}
const AddRule: React.FC<AddRuleProps> = ({ addGroupRule }) => {
  const { rules } = useConfigContext();
  const [selectedRule, setSelectedRule] = React.useState<Rule | undefined>();
  const [segment, setSegment] = React.useState<number>(0);
  const [value, setValue] = React.useState<string>('');

  const handleAddRule = () => {
    addGroupRule(selectedRule?.id || 0, segment, value);

    setSelectedRule(undefined);
    setSegment(0);
    setValue('');
  };

  return (
    <Wrapper>
      <Label label="Group Rule">
        <Select
          value={selectedRule?.id || ''}
          onChange={(e) => {
            setSelectedRule(
              rules.find((rule) => rule.id === parseInt(e.target.value))
            );
          }}
        >
          <option value="">{'Select a rule'}</option>
          {rules.map((rule) => (
            <option key={`new-rule-${rule.id}`} value={rule.id}>
              {rule.name}
            </option>
          ))}
        </Select>
      </Label>
      <Label label="Segment">
        <Select
          value={segment}
          onChange={(e) => setSegment(parseInt(e.target.value))}
        >
          <option value="">Select segment</option>
          {segmentOptions.map((seg, idx) => (
            <option key={`segment-${seg}-${idx}`} value={seg}>
              {segmentLabels[seg]}
            </option>
          ))}
        </Select>
      </Label>
      <Label label="Value">
        <Input
          type={
            selectedRule?.valueType === ValueType.NUMBER ? 'number' : 'text'
          }
          value={value.toString()}
          onChange={(e) => setValue(e.target.value)}
        />
      </Label>
      <Button
        style={{ padding: '0', }}
        color={ButtonColors.add}
        onClick={handleAddRule}
      >
        Add Rule
      </Button>
    </Wrapper>
  );
};

export default AddRule;

