import Label from 'components/input/Label';
import Modal from 'components/Modal';
import React, { FC } from 'react';
import { useConfigContext, Group } from '../../contexts/configurationContext';


const RemoveModal: FC<{ group: Group }> = ({group}) => {
  const { deleteGroup } = useConfigContext();

  return (
    <Modal
      title="Really remove?"
      buttons={[
        {
          label: 'Remove',
          onClick: () => {
            deleteGroup(group.id);
          },
        },
      ]}
    >
      <Label label="Remove:">{group.name}</Label>
    </Modal>
  );
};

export default RemoveModal;

