import React, { createContext, useContext, useEffect, useState } from 'react';

export enum ValueType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
}

export type RuleTest = {
  id: number;
  name: string;
  facilityId: number;
  segment: string[];
  value: ValueType;
};

type RulesContextType = {
  rules: RuleTest[];
  newRule: RuleTest;
  addRule: () => void;
  deleteRule: (id: number) => void;
  editRule: (updatedRule: RuleTest) => void;
  updateData: (rowIndex: number, columnId: string, value: unknown) => void; // New method
  setNewRule: (rule: Partial<RuleTest>) => void;
};

const initialNewRule: RuleTest = {
  id: 0,
  name: '',
  facilityId: 0,
  segment: [],
  value: ValueType.STRING,
};

export const RulesContext = createContext<RulesContextType>(null!);

export const RulesContextProvider: React.FC = ({ children }) => {
  const [rules, setRules] = useState<RuleTest[]>([]);
  const [newRule, setNewRuleState] = useState<RuleTest>(initialNewRule);

  useEffect(() => {
    fetchRules();
  }, []);

  // Fetch rules from API
  const fetchRules = async () => {
    try {
      const response = await fetch('/api/Configuration/get/rules');
      const data = await response.json();
      setRules(data);
    } catch (error) {
      console.error('Error fetching rules:', error);
    }
  };

  const setNewRule = (rule: Partial<RuleTest>) =>
    setNewRuleState((prev) => ({ ...prev, ...rule }));

  useEffect(() => {
    fetchRules();
  }, []);

  const addRule = async () => {
    // Logic to add a rule and update context state
  };

  const deleteRule = async (id: number) => {
    // Logic to delete a rule and update context state
  };

  const editRule = async (updatedRule: RuleTest) => {
    // Logic to edit a rule and update context state
  };

  // Centralized update function for table cells
  const updateData = (rowIndex: number, columnId: string, value: unknown) => {
    // Update frontend state first for responsiveness
    setRules((prevRules) =>
      prevRules.map((rule, index) =>
        index === rowIndex ? { ...rule, [columnId]: value } : rule
      )
    );

    // Call editRule to save the change in the backend
    const updatedRule = { ...rules[rowIndex], [columnId]: value } as RuleTest;
    editRule(updatedRule);
  };

  return (
    <RulesContext.Provider
      value={{
        rules,
        newRule,
        setNewRule: (rule) => setNewRuleState((prev) => ({ ...prev, ...rule })),
        addRule,
        deleteRule,
        editRule,
        updateData, // Providing updateData here
      }}
    >
      {children}
    </RulesContext.Provider>
  );
};

export const useRulesContext = () => useContext(RulesContext);

