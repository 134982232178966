import React from 'react';
import { Checkbox } from '@bitio/react-components';
import { Card } from 'components/Card';
import { BsListCheck } from 'react-icons/bs';
import { css, styled } from 'styled-components';

const Title = styled.h4`
  font-size: 16px;
  margin: 0;
`;
export const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
`;
const DropdownContent = styled(Card.Content)<{
  $isOpen: boolean;
  $maxHeight?: string;
}>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: ${(props) => (props.$isOpen ? props.$maxHeight || '300px' : '0')};
  transition: max-height 0.3s ease;
  background: white;
  z-index: 9;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${(props) =>
    props.$isOpen &&
    css`
      overflow-y: auto;
      border: 1px solid ${(props) => props.theme.palette.grey.fg};
    `}
`;

interface ScrollableCheckboxProps {
  zones: { gid: string; name: string }[];
  selectedZoneIds: string[];
  onChange: (selectedZoneIds: string[]) => void;
}
const ScrollableCheckbox: React.FC<ScrollableCheckboxProps> = ({
  zones,
  selectedZoneIds,
  onChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleCheckboxChange = (id: string) => {
    if (selectedZoneIds.includes(id)) {
      onChange(selectedZoneIds.filter((zoneId) => zoneId !== id));
    } else {
      onChange([...selectedZoneIds, id]);
    }
  };

  return (
    <Card.Wrapper $scrollable>
      <Card.Header $scrollable onClick={() => setIsOpen(!isOpen)}>
        <BsListCheck size={20} />
        <Title>Select Zones</Title>
      </Card.Header>
      <DropdownContent $isOpen={isOpen} $maxHeight="300px">
        <CheckboxList>
          {zones.map((zone) => {
            return (
              <Checkbox
                key={zone.gid}
                type="checkbox"
                id={zone.gid}
                value={zone.name}
                checked={selectedZoneIds.includes(zone.gid)}
                onChange={() => handleCheckboxChange(zone.gid)}
              >
                {zone.name}
              </Checkbox>
            );
          })}
        </CheckboxList>
      </DropdownContent>
    </Card.Wrapper>
  );
};

export default ScrollableCheckbox;

