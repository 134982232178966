import styled, { css } from 'styled-components';
interface CardVariants {
  default?: boolean;
  action?: boolean;
  active?: boolean;
  selected?: boolean;
  present?: boolean;
  past?: boolean;
  $scrollable?: boolean;
  maxHeight?: string;
}
const CardWrapper = styled.div<CardVariants>`
  position: relative;
  border-radius: 13px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  min-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border: 1px solid ${(props) => props.theme.palette.common.primaryGray};

  /* Custom styles based on state */
  ${(props) =>
    props.default &&
    css`
      border: 2px solid ${props.theme.palette.secondary.bg};
    `}
  ${(props) =>
    props.action &&
    css`
      background-color: ${props.theme.palette.secondaryLight.bg};
    `}
  ${(props) =>
    props.active &&
    css`
      color: ${({ theme }) => theme.palette.common.lightBorder};
      border: none;
    `}
  ${(props) =>
    props.present &&
    css`
      border: 2px solid ${props.theme.palette.secondary.bg};
    `}

  ${(props) =>
    props.past &&
    css`
      color: ${({ theme }) => theme.palette.common.lightBorder};
      border: none;
    `}

  ${(props) =>
    props.selected &&
    css`
      scroll-snap-align: center;
      border-top: 5px solid ${props.theme.palette.primary.bg};
      border-bottom: 5px solid ${props.theme.palette.primary.bg};
      min-width: 535px;
    `}

  ${(props) =>
    props.$scrollable &&
    css`
      max-height: ${props.maxHeight || '300px'};
      max-width: 300px;
      padding: 0;
      border-radius: 5px;
      border: none;
    `}
`;

const CardHeader = styled.div<CardVariants>`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$scrollable &&
    css`
      background-color: ${({ theme }) => theme.palette.secondaryLight.bg};
      position: sticky;
      top: 0;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px; 
      padding:10px;F
      align-items: center;
    `}
`;

const CardContent = styled.div<CardVariants>`
  margin: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  ${(props) =>
    props.$scrollable &&
    css`
      overflow-y: auto;
    `}
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5px;
`;

export const Card = {
  Wrapper: CardWrapper,
  Header: CardHeader,
  Content: CardContent,
  Footer: CardFooter,
};

