import Select from 'components/input/Select';
import { Facilities } from 'pages/admin/Users/userAdminContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { ApplicationState } from 'store/definitions';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import {
  facilityChangeActive,
  facilityChangedComplete,
  zoneChangeActive,
} from 'store/ui/action';
import {
  getAdminStatus,
  getUser,
  selectUISettingSites,
  selectUISettingZones,
} from 'store/uiSettings/selectors';
import styled, { css } from 'styled-components';
import { FacilityPageProps } from 'topdefinitions';
import logoSrc from 'images/logo.png';
import { selectAvailableZones } from 'store/zone/selector';
import { ZoneFlag } from 'store/zone/reducer';
import { NavLink } from 'react-router-dom';
import Route from './Route';
import Notification from 'components/Notification';

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;
const LeftContent = styled.div``;
const RightContent = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;

  > div {
    margin-left: 20px;
  }
`;
const Logo = styled.img`
  width: 140px;
`;

const Link = styled.div`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.bg};
  cursor: pointer;
`;

const LinkButton = styled(NavLink)<{ active: boolean }>`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 10px 13px;
    margin: 0;

    color: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
    border-radius: 5px;
    transition: background-color 0.2s;

    ${(props) =>
      props.active &&
      css`
        padding: 9px 12px;
        font-weight: bold;
        border: 2px solid ${({ theme }) => theme.palette.primary.bg};
      `}
  }
  &:hover {
    background: ${({ theme }) => theme.palette.grey.bg};
    outline: none;
  }

  &:focus {
    font-weight: 700;
  }
`;

interface Props {}
const AppNavBar: React.FC<Props> = () => {
  const isAdmin = useSelector(getAdminStatus);
  const history = useHistory();
  //const [onAdminSite, setOnAdminSite] = useState<boolean>();
  const onAdminSite = history.location.pathname.startsWith('/admin/');
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  const [currentFacility, setCurrentFacility] = useState<string>(
    routeInfo?.params.facilityId!
  );
  const dispatch = useDispatch();
  var zones = useSelector(selectAvailableZones);

  var user = useSelector(getUser);
  var facilities = user.facilities;
  const activeFacility = useSelector(
    (state: ApplicationState) => state.uiState.activeFacility
  );

  useEffect(() => {
    if (currentFacility !== activeFacility) {
      var facilityZones = zones.filter((f) => f.sitePath === activeFacility);

      setCurrentFacility(activeFacility);
      dispatch(facilityChangedComplete());
    }
  }, [activeFacility]);
  var route = generatePath(
    routeInfo?.params?.zoneId ? Route.EVPriceZGraph : Route.EVPriceGraph,
    !routeInfo?.params ? { facilityId: facilities[0].id } : routeInfo!.params!
  );
  // console.log(routeInfo);

  return (
    <Wrapper>
      <LeftContent>
        <Logo src={logoSrc} alt="logo" />
      </LeftContent>
      <RightContent>
        <Notification />
        {onAdminSite && (
          <Link
            onClick={() => {
              history.goBack();
            }}
          >
            Go back
          </Link>
        )}
        {!onAdminSite && isAdmin && (
          <Link
            onClick={() => {
              //setOnAdminSite(true);
              history.push('/admin/users');
            }}
          >
            Admin Page
          </Link>
        )}
        {/* {onAdminSite && (
          <Link
            onClick={() => {
              setOnAdminSite(false);
              history.goBack();
            }}
          >
            Back to homepage
          </Link>
        )} */}
        <Link
          onClick={() => {
            window.location.href = '/user/logout';
          }}
        >
          Logout
        </Link>
      </RightContent>
    </Wrapper>
  );
};

export default AppNavBar;
