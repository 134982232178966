import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import {
  useConfigContext,
  Group,
  GroupRule,
} from '../../contexts/configurationContext';
import Label from 'components/input/Label';
import styled from 'styled-components';
import Input from 'components/input/Input';
import ScrollableCheckbox from '../ScrollableCheckbox';
import RulesTable from '../tables/RulesTable';
import AddRule from '../tables/AddRule';
import Loader from 'components/Loader';
import useModalStack from 'components/Modal/useModalStack';

interface GroupModalProps {
  editableGroup?: Group;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding-bottom: 20px;
`;
const TopColumn = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
const BottomColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModalWrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  gap: 20px;
`;
const initialGroup: Group = {
  name: '',
  id: 0,
  zoneIds: [],
  rules: [],
  facilityId: 0,
};

const GroupModal: React.FC<GroupModalProps> = ({ editableGroup }) => {
  const { zones, editGroup, createGroup, isLoading } = useConfigContext();
  const modal = useModalStack();
  const [group, setGroup] = useState<Group>(editableGroup || initialGroup);
  const [groupName, setGroupName] = useState<string>(editableGroup?.name || '');
  const [selectedZones, setSelectedZones] = useState<string[]>(
    editableGroup?.zoneIds || []
  );

  const groupId = editableGroup?.id || 0;

  const addGroupRule = (ruleId: number, segment: number, value: string) => {
    var rules = [
      ...group.rules,
      {
        id: 0,
        groupId: groupId,
        configurationId: ruleId,
        value: value,
        segment: segment,
      },
    ];

    setGroup({ ...group, rules: rules });
  };

  const updateGroupRule = (updatedRule: GroupRule) => {
    console.log('updateGroupRule', updatedRule);
    setGroup({
      ...group,
      rules: group.rules.map((rule) =>
        rule.id === updatedRule.id ? updatedRule : rule
      ),
    });
  };

  const deleteGroupRule = (ruleId: number) => {
    setGroup({
      ...group,
      rules: group.rules.filter((rule) => rule.id !== ruleId),
    });
  };

  const handleSubmit = async () => {
    if (!groupName || selectedZones.length === 0) {
      alert('Please fill all fields');
      return;
    }

    if (editableGroup) {
      console.log('Edit group', group);
      await editGroup(group);
    } else {
      await createGroup(group);
    }
    modal.pop();
  };

  useEffect(() => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      name: groupName,
      zoneIds: selectedZones,
    }));
  }, [groupName, selectedZones]);

  return (
    <ModalWrapper
      title={editableGroup ? 'Edit Group' : 'Create New Group'}
      buttons={[
        {
          disabled:
            groupName.length === 0 || selectedZones.length === 0 || isLoading,
          label: editableGroup ? 'Save Changes' : 'Create',
          onClick: handleSubmit,
          dontPopAfterClick: true,
        },
      ]}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TopColumn>
            <Label label="Group Name">
              <Input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Enter group name"
              />
            </Label>
            <Label label="Group Zones">
              <ScrollableCheckbox
                zones={zones.map((zone) => ({
                  gid: zone.gid,
                  name: zone.name,
                }))}
                selectedZoneIds={selectedZones}
                onChange={setSelectedZones}
              />
            </Label>
          </TopColumn>
          <BottomColumn>
            <h5>Add group rules</h5>
            <AddRule addGroupRule={addGroupRule} />
            <RulesTable
              groupRules={group.rules}
              updateGroupRule={updateGroupRule}
              deleteGroupRule={deleteGroupRule}
            />
          </BottomColumn>
        </Wrapper>
      )}
    </ModalWrapper>
  );
};

export default GroupModal;

